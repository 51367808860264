import { defineComponent } from "vue";
import pidModel from "../../components/pidModel/index.vue";
import ptitle from "../../components/pageTitle/index.vue";
import individualDetailInfo from "./components/individualDetailInfo.vue";
import loading from "../lek/components/TMap/components/loading.vue";
import { useAppStore } from "@/store";
import { Search } from '@element-plus/icons-vue';
const store = useAppStore();
const TMap = window.TMap;
import * as api from "@/api";
export default defineComponent({
  components: {
    ptitle,
    pidModel,
    individualDetailInfo,
    loading
  },
  data() {
    return {
      store_: store,
      isLogin: false,
      ok: "已审核",
      no: "未审核",
      list: [],
      typeList: [],
      successList: [],
      img: "",
      topImgList: [],
      leftImgList: [],
      rightImgList: [],
      defaultMsg: [],
      detailShow: false,
      SpeciesList: [],
      buttonClickIndex: 0,
      Species: {
        //物种
        selectSpecies: store.photoMode('allSpecies'),
        id: 0,
        SpeciesList: []
      },
      Population: {
        //种群
        selectPopulation: store.photoMode('allPopulation'),
        id: 0,
        PopulationList: []
      },
      Time: {
        //时间排序
        selectTime: store.photoMode('byTime'),
        id: 0,
        TimeList: [{
          id: 0,
          text: store.photoMode('byTime')
        }, {
          id: 1,
          text: store.photoMode('byName')
        }]
      },
      Fin: {
        selectFin: store.photoMode('left'),
        id: 1,
        FinList: [{
          id: 1,
          text: store.photoMode('left')
        }, {
          id: 2,
          text: store.photoMode('right')
        }]
      },
      ImgPosition: 1,
      TMapIsReady: false,
      map: [],
      point: [],
      mapDatas_ppid: [],
      loading: true,
      moveNum: 0,
      pageIndex: 1,
      pageIndexLoading: false,
      can_tToNextPage: false,
      pageCount: 0,
      search: Search,
      inputButtonShow: false,
      interval: [],
      path: '',
      windowWidth: 0,
      query: [],
      title: store.photoMode('title'),
      is_CN: store.getLang()
    };
  },
  async created() {
    // this.getList(0, -1);//传index
    store.setTopBarIndex(2);
    this.query = store.getRouter();
    if (window.location.href.length > 30) {
      let href = window.location.href;
      let query = {
        pathId: Number(href.substring(href.indexOf('pathId=') + 7, href.indexOf('listType'))),
        listType: Number(href.substring(href.indexOf('&listType=') + 10))
      };
      this.query = query;
    }
    if (this.query == "") {
      //这样做的原因是因为页面的参数都是通过临时缓存传递的，当页面刷新后，临时数据会被清除，所以当用户在模版页面刷新，此时临时数据被刷新掉了的时候，就无法获取当前页的数据，所以跳回首页，让用户自己点击导航栏跳转
      this.$router.push({
        path: "/"
      });
    }
    this.getSpecies();
    this.getBanner();
    this.getList(0, 0, 0);
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
  watch: {
    $route: "getBanner"
  },
  methods: {
    init() {
      this.TMapIsReady = true;
      this.map = new TMap.Map("tmap-container-ppid", {
        // center: new TMap.LatLng(dataMap.latitude, dataMap.lngitude),
        zoom: 9,
        minZoom: 9,
        maxZoom: 12,
        viewMode: "2D"
      });
      this.point = new TMap.MarkerCluster({
        id: "cluster-ppid",
        map: this.map,
        enableDefaultStyle: true,
        minimumClusterSize: 50,
        // geometries: mapData.mapdatas,
        geometries: [],
        zoomOnClick: true,
        gridSize: 200,
        averageCenter: false,
        maxZoom: 1
      });
    },
    addPoint() {
      //添加点数据
      this.point.setGeometries();
      // mapData.label.setVisible(false)
    },

    async getBanner() {
      //获取banner图
      let interval = setInterval(() => {
        this.moveNum = store.getMoveDistance();
      }, 200);
      let path = this.$route.fullPath;
      this.path = path.split('?')[0];
      this.windowWidth = window.innerWidth;
      this.detailShow = false;
      if (this.$route.fullPath.indexOf('pid') == -1) {
        clearInterval(interval);
      }
      // let cc = (store as any).getRouter().query;
      if (this.query.adNum == "" || this.query.adNum == undefined) {
        this.query.adNum = 25;
      }
      if (this.query.pathId != 106) {
        clearInterval(interval);
      }
      try {
        const img = await api.getAdImgAndVideo(this.query.adNum);
        this.img = img.data.ad_list[0].pic;
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 获取图片的数据的数据
     */
    async getList(species, populationL, time) {
      this.pageIndexLoading = true;
      let data = {
        data_type: "PID",
        species_id: species ? species : 0,
        sort_type: time ? time : 0,
        population_id: populationL ? populationL : 0,
        size: 16,
        page: this.pageIndex
      };
      // let lengthData = data
      // lengthData.size = 99999
      try {
        // const length = await api.getBrowseData(lengthData)
        const res = await api.getBrowseData(data);
        this.pageCount = res.data.count / 16 % 1 === 0 ? res.data.count : Number((res.data.count / 16).toFixed(0)) + 1;
        if (res.data.list.length < 1 && this.pageIndex != 1) {
          this.pageIndex -= 1;
          this.can_tToNextPage = true;
        } else {
          this.list = res.data.list;
        }
        this.subNumber();
        this.pageIndexLoading = false;
        if (this.list.length == 16) {
          this.can_tToNextPage = false;
        } else {
          this.can_tToNextPage = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    addPageIndex() {
      if (this.pageIndexLoading == false) {
        this.pageIndex = Number(this.pageIndex) + 1; //先转成number类型不然
        this.getList(this.Species.id, this.Population.id, this.Time.id);
      }
    },
    reducePageIndex() {
      if (this.pageIndexLoading == false) {
        if (this.pageIndex > 1) {
          this.can_tToNextPage = false;
          this.pageIndex -= 1;
          this.getList(this.Species.id, this.Population.id, this.Time.id);
        }
      }
    },
    onClickFormPaginationComponent() {
      console.debug(this.pageIndex, '当前的页码');
    },
    async getSpecies() {
      //获取物种
      let res = await api.getSpeciesList({
        data_type: "PID"
      });
      this.Species.SpeciesList = res.data.list;
      let restwo = await api.getPopulationList({
        species_id: 1
      });
      let Species = {
        alias: store.photoMode('allSpecies'),
        id: 0
      };
      let Population = {
        area: store.photoMode('allPopulation'),
        id: 0,
        species_id: 0
      };
      this.Population.PopulationList = restwo.data.list;
      this.Population.PopulationList.unshift(Population);
      this.Species.SpeciesList.unshift(Species);
    },
    subNumber() {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].individual == 1) {
          this.list[i].longitude = Number(this.list[i].longitude).toFixed(2);
          this.list[i].latitude = Number(this.list[i].latitude).toFixed(2);
        }
      }
    },
    login() {
      this.isLogin = true;
    },
    /**
     * 点击后获取详情
     * @param item 点击的图片所属数组
     */
    async getDetails(item) {
      this.detailShow = true;
      setTimeout(async () => {
        this.moveNum = store.getMoveDistance();
        if (!this.TMapIsReady) {
          //
          this.init();
        }
        try {
          const res = await api.individualDetailInfo({
            data_type: "PID",
            data_id: item.id
          });
          this.topImgList = res.data.lr_image;
          this.rightImgList = res.data.fin_right_image;
          this.leftImgList = res.data.fin_left_image;
          this.defaultMsg = res.data.detail;
          let ppid_TMap = res.data.data_list.map((items, index) => {
            if (isNaN(parseFloat(items.latitude)) == false || isNaN(parseFloat(items.longitude)) == false) {
              return {
                id: "label",
                styleId: "default",
                position: new TMap.LatLng(parseFloat(items.latitude), parseFloat(items.longitude)),
                content: `${items.species}`,
                ccId: `${items.id}`
              };
            }
          });
          this.mapDatas_ppid = ppid_TMap;
          if (ppid_TMap) {
            let center = new TMap.LatLng(ppid_TMap[0].position.lat, ppid_TMap[0].position.lng);
            this.setTMapPoint();
            this.map.setCenter(center);
          }
        } catch (e) {
          console.error(e);
        }
      }, 100);
    },
    /**
     * 配置地图点
     */
    setTMapPoint() {
      this.point.setGeometries(this.mapDatas_ppid);
    },
    close() {
      this.detailShow = false;
    },
    selectOption(id, type) {
      let cc = () => {
        this.getList(this.Species.id, this.Population.id, this.Time.id);
      };
      switch (type) {
        case 1:
          this.Species.id = id;
          this.pageIndex = 1;
          cc();
          break;
        case 2:
          this.Population.id = id;
          this.pageIndex = 1;
          cc();
          break;
        case 3:
          this.Time.id = id;
          this.pageIndex = 1;
          cc();
          break;
        case 4:
          this.ImgPosition = id;
          // this.pageIndex = 1
          // cc();
          break;
        default:
          break;
      }
    },
    scroll(e) {
      console.debug(e, "这是滑动的");
    },
    /**
     * 分页器失去焦点是触发
     */
    blur() {
      setTimeout(() => {
        this.inputButtonShow = false;
      }, 5000);
    },
    /**
     * 分页器获得焦点是触发
     */
    focus() {
      this.inputButtonShow = true;
    },
    /**
     * 这个是分页器搜索组件的点击事件
     */
    inputFindComponent() {
      if (this.pageIndex < 1) {
        this.pageIndex = 1;
      } else if (this.pageIndex > this.pageCount) {
        this.pageIndex = this.pageCount;
      }
      this.getList(this.Species.id, this.Population.id, this.Time.id);
      this.inputButtonShow = false;
    }
  }
});